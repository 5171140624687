.game-card {
  background-color: transparent !important;
  border: solid 1px lightgrey !important;
}

.game-board-wrapper {
  position: relative;
  margin-left: 2.5%;
  margin-top: 20vh;
  width: 95%;
  height: 0;
  padding-bottom: 50%;
  /*border: 1px solid red;*/
}

@media (min-width: 595px) {
  .game-board-wrapper {
    margin-top: 15vh;
  }
}

.game-board-wrapper-inner {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 1024px) {
  .game-board-wrapper {
    position: relative;
    margin-top: 7.5%;
    margin-left: 10%;
    /* margin: 50px; */
    width: 80%;
    height: 0;
    padding-bottom: 40%;
  }
}

@media (min-width: 1298px) {
  .game-board-wrapper {
    position: relative;
    margin-top: 5%;
    margin-left: 15%;
    /* margin: 50px; */
    width: 70%;
    height: 0;
    padding-bottom: 35%;
  }
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1101;
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 15%;
  bottom: auto;
  padding: 10px;
  margin: auto;
  background: white;
  border-radius: 5px;
  z-index: 1101;
}

.popup-header {
  text-align: center;
}

.btn-popup-close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 10px;
}

@media only screen and (max-width: 950px) {
  .popup_inner {
    position: absolute;
    left: 5%;
    right: 5%;
    top: 15%;
    bottom: auto;
    padding: 10px;
    margin: auto;
    background: white;
    border-radius: 5px;
    z-index: 2;
  }
}

.card-img-custom {
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  transition: .5s ease;
}

@media (min-width: 576px) {
  .card-img-custom:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    box-shadow: 0 10px 10px rgba(0, 0, 0, .2) !important;
  }
}

@media (max-width: 575px) {
  .card-img-custom:hover {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    box-shadow: 0 10px 10px rgba(0, 0, 0, .2) !important;
  }
}

.chessboard-wrapper {
  position: absolute;
  top: 20%;
  left: 1%;
  right: 1%;
  bottom: 20%;
  padding: 1px;
}

@media (min-width: 575px) {
  .chessboard-wrapper {
    left: 5%;
    right: 5%;
  }
}

@media (min-width: 1024px) {
  .chessboard-wrapper {
    left: 20%;
    right: 20%;
  }
}

.chessboard-container {
  margin: auto;
  width: 100%;
  height: 100%;
}

.c-white {
  background-color: #f0dab5;
  /*background-color: #F0D9B5;*/
}

.c-black {
  background-color: #b58763;
  /*background-color: #B58863;*/
}

.c-sel {
  background-color: #626047;
}