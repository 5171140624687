.App {
  font-family: "Montserrat", serif !important;
  text-align: center;
  /* font-weight: Bold; */
}

.hide-span-mobile span {
  display: none;
}

.hidden-mobile {
  display: none;
}

.nav-info {
  display: inline-block;
}

.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 500px;
}

.navbar {
  position: relative;
  padding: 0.5rem 1rem;
  /* min-height: 50px; */
}

.gradient {
  /* background: linear-gradient(120deg, #7f70f5, #0ea0ff); */
  /* background: linear-gradient(120deg, #00df70, #0ea0ff); */
  /* background: linear-gradient(60deg, #b60d6f, #0ea0ff); */
  /* background: linear-gradient(-60deg, #f857a6, #ff5858); */
  /* background: linear-gradient(120deg, #00f260, #0575e6); */
  /* background: linear-gradient(120deg, #7b4397 0%, #b5238a 59%, #dc2430 110%); */
  background: linear-gradient(120deg, #65377c 0%, #961d71 59%, #b81e28 120%);
  color: #fff;
}

@media (min-width: 768px) {
  .hide-span-mobile span {
    display: inline;
  }

  .hidden-mobile {
    display: inherit;
  }

  .nav-info {
    display: none;
  }
}

.hide-380 {
  display: none;
}

@media (min-width: 380px) {
  .hide-380 {
    display: inline;
  }
}

/* button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
} */

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.link {
  cursor: pointer;
  color: #007bff;
}

.link:hover {
  text-decoration: underline;
  color: #0056b3;
}

.btn-xxs {
  border: none;
  box-shadow: none !important;
  outline: none !important;
}

/* Image Placeholder */

.placeholder {
  background: transparent;
  padding-top: 56.25%;
  position: relative;
  box-sizing: border-box;
}

.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50px;
  height: 20px;
  text-align: center;
  font-size: 10px;
  box-sizing: border-box;
}

.spinner div {
  background-color: #11bba7;
  height: 100%;
  width: 6px;
  display: inline-block;
  box-sizing: border-box;

  -webkit-animation: stretch 1.2s infinite ease-in-out;
  animation: stretch 1.2s infinite ease-in-out;
}

.rect2 {
  -webkit-animation-delay: -1.1s !important;
  animation-delay: -1.1s !important;
}

.rect2 {
  -webkit-animation-delay: -1.1s !important;
  animation-delay: -1.1s !important;
}

.rect3 {
  -webkit-animation-delay: -1.0s !important;
  animation-delay: -1.0s !important;
}

.rect4 {
  -webkit-animation-delay: -0.9s !important;
  animation-delay: -0.9s !important;
}

.rect5 {
  -webkit-animation-delay: -0.8s !important;
  animation-delay: -0.8s !important;
}

@-webkit-keyframes stretch {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4)
  }
  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes stretch {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.landing-placeholder {
  position: absolute;
}

.bio {
  width: 90%;
  margin: auto auto -20px auto;
  text-align: justify;
  padding-top: 1em;
}

.splash-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(15, 15, 15, 0.5);
  z-index: 1101;
}

.splash-screen-inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  padding: 10px;
  margin: auto;
  border-radius: 5px;
  z-index: 2;
}

@media (min-width: 576px) {
  .btn-cookie-close {
    display: none;
  }
}