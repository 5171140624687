.pad {
  position: relative;
  padding-bottom: 50%;
}

.game-board {
  position: relative;
  /*padding-left: 2%;*/
  width: 100%;
  height: 100%;
}

.tile {
  position: absolute;
  width: 3.13%;
  height: 6.26%;
}

.blank {
  position: absolute;
  z-index: 0;
  background-color: transparent;
}

.board {
  z-index: 0;
  background-color: rgba(108, 117, 125, 1);
}

.food {
  z-index: 0;
  background-color: rgb(40, 169, 67);
}

.snake {
  z-index: 1;
  background-color: rgb(0, 123, 255);
}

.spfood {
  z-index: 0;
  background-color: rgb(170, 40, 140);
}

.button-group:focus {
   box-shadow: none !important;
}